import * as React from "react"
import styled from "styled-components"
import { Button03 } from "../Button03"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1300, min: 900 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

const Container = styled.div`

  width: 100%;
  display: flex;
  flex-direction: column;
  margin : 3em 0 ;
  padding: 0 20%;
  position: relative;
  justify-content: center;

a{
    color: #292929;
}
.title{
    font-size: 37px;
    font-weight: 700;
    line-height: 120%;
    width: 90%;
}
.description{
    color: #292929;
    margin: 1em 0;
    font-size: 20px;

}
.subtitle{
    margin: 1em 0;
    color: #0044B0;
    font-weight: 700;
    font-size: 26px;
}

a{
    display: flex;
    justify-content: center;
    margin: 1em 0;

}

.blocks{
    max-width: 1440px;
    position: relative;
    display: flex;
    flex-direction: column;

.block1{

    margin-left: 0;

    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    .image{
        width: 80px;
        margin: 0 10px 0 0;
        position: relative;

                    img{
                        width: 70px;
                        height: 60px;
                       // padding: 5px 10px 10px 10px ;
                        position: relative;
                        object-fit: contain;

                    }
    }
    .text{
        width: 78%;
        position: relative;

                    .block_title{
                        color: #292929;
                        font-weight: 600;
                        font-size: 22px;
                        }
                    .info{
                        font-size: 18px;

                    }
    }
}
.block2{

margin-left: 5%;

margin-bottom: 2em;
display: flex;
flex-direction: row;
width: 100%;
position: relative;
.image{
    width: 80px;
    margin: 0 10px 0 0;
    position: relative;

                img{
                    width: 70px;
                    height: 60px;
                   // padding: 5px 10px 10px 10px ;
                    position: relative;
                    object-fit: contain;

                }
}
.text{
    width: 73%;
    position: relative;

                .block_title{
                    color: #292929;
                    font-weight: 600;
                    font-size: 22px;
                    }
                .info{
                    font-size: 18px;

                }
}
}
.block3{

margin-left: 10%;

margin-bottom: 2em;
display: flex;
flex-direction: row;
width: 100%;
position: relative;
.image{
    width: 80px;
    margin: 0 10px 0 0;
    position: relative;

                img{
                    width: 70px;
                    height: 60px;
                    //padding: 5px 10px 10px 10px ;
                    position: relative;
                    object-fit: contain;

                }
}
.text{
    width: 68%;
    position: relative;

                .block_title{
                    color: #292929;
                    font-weight: 600;
                    font-size: 22px;
                    }
                .info{
                    font-size: 18px;

                }
}
}
}

.redes{
    max-width: 1200px;
    position: relative;
    display: flex;
    flex-direction: column;

.redes1{

    margin-left: 0;

    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    .img_redes{
        width: 60px;
        margin: 0 10px 0 0;
        position: relative;

                    img{
                        width: 50px;
                        height: 50px;
                       // padding: 5px 10px 10px 10px ;
                        position: relative;
                        object-fit: contain;

                    }
    }
    .text_redes{
        width: 100%;
        position: relative;

                    .redes_title{
                        color: #292929;
                        font-weight: 600;
                        font-size: 22px;
                        }
                    .info_redes{
                        font-size: 18px;

                    }
    }
}
.redes2{


margin-bottom: 2em;
display: flex;
flex-direction: row;
width: 100%;
position: relative;

a{
    display: flex;
    justify-content: flex-start;
}

.img_redes{
    width: 250px;
    margin: 0 18px 0 0;
    position: relative;

                img{
                    width: 250px;
                    height: 74px;
                    //padding: 5px 10px 10px 10px ;
                    position: relative;
                    object-fit: contain;

                }
}
.text_redes{
   
    position: relative;

                .redes_title{
                    color: #292929;
                    font-weight: 600;
                    font-size: 22px;
                    }
                .info_redes{
                    font-size: 18px;

                }
}
}
}


.photos{
    position: relative;
    margin: 1em 0;

    .carrusel{
        img{
  object-fit: cover;
  margin: 0 100px;
  max-width: 90%;
}
.img_container{
  display: flex;
  justify-content: center;
}
  .react-multiple-carousel__arrow {
    z-index: 1;
  }
  .react-multiple-carousel__arrow::before {
    color:#0044B0;
    font-size: 30px;
  }
  .react-multiple-carousel__arrow {
    background:none;
  }
  .react-multi-carousel-item {
    display: flex;
  justify-content: center;
  width:100%;
  padding: 0 25px;
  }
  .react-multiple-carousel__arrow--right {
    right: -10px;
    }
    .react-multiple-carousel__arrow--left {
    left: -10px;
    }
    .react-multi-carousel-track{
    align-items:center;
    }
}}
  @media (max-width:1200px) {
    padding: 0 10%;}

@media (max-width:950px) {
    padding: 0 10%;
    .title{
        font-size: 30px;
    }
    .description{
        font-size: 14px;
        text-align: justify;
    }
    .subtitle{
        font-size: 22px;

    }
    .blocks{
.block1{
            .image{
                    width: 70px;
                    margin: 0 5px 0 0;
                    img{
                        width: 60px;
                        height:50px;}
                    }
        .text{
            .block_title{
                font-size: 18px;
        }
        .info{
                    font-size: 14px;
                    text-align:justify
                }
    }
}
.block2{
    .image{
                    width: 70px;
                    margin: 0 5px 0 0;
                    img{
                        width: 60px;
                        height:50px;}
                    }
        .text{
            .block_title{
                font-size: 18px;
        }
        .info{
                    font-size: 14px;
                    text-align:justify

                }
    }
}
.block3{
    .image{
                    width: 70px;
                    margin: 0 5px 0 0;
                    img{
                        width: 60px;
                        height:50px;}
                    }
        .text{
            .block_title{
                font-size: 18px;
        }
        .info{
                    font-size: 14px;
                    text-align:justify

                }
    }
}
}

.redes{
    a{
        display: flex;
        justify-content: flex-start;
    }
        .redes1{

            .img_redes{
                        width: 50px;
                        margin: 0 20px 0 0;
                        img{
                            width: 50px;
                            height:50px;}
                        }
            .text_redes{
                width: 55%;

                    .redes_title{
                        font-size: 18px;
                }
                    .info_redes{
                            font-size: 14px;
                        }
            }
    }
    .redes2{

        .img_redes{
                    width: 190px;
                    margin: 0 5px 0 0;
                    display: flex;
        align-items: center;
                    img{
                        width: 190px;
                        height:56px;}
                    }
        .text_redes{
            .redes_title{
                font-size: 18px;
        }
        .info_redes{
                    font-size: 14px;
                }
    }
    }
}
}



@media (max-width:600px) {
    margin : 2em 0 ;
    padding: 0 8%;

    .title{
        font-size: 23px;
    }
    .description{
        font-size: 14px;
        text-align: justify;
    }
    .subtitle{
        font-size: 19px;

    }

    .blocks{
        .block1{
            .image{
                margin: 0;

                img{
                    width: 50px;
                    height: 40px;
                }
            }
        }

        .block2{
            .image{
                margin: 0;

                img{
                    width: 50px;
                    height: 40px;
                }
            }
        }

        .block3{
            .image{
                margin: 0;

                img{
                    width: 50px;
                    height: 40px;
                }
            }
        }
    }

.redes{
    .redes2{
        margin-left: 0%;
        flex-direction: column;
        a{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        .text_redes{
            text-align: right;
            width: 85%;
        }}

    }
}


}

`

const ComunidadItcad=({data, url, images, description}) =>{
    const paragraphs = description ? description.split("/") : [];


return(

<Container>

<div className="title">
{data?.title}
</div>

<div className="description">

<div>{paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                    ))}</div>
</div>

<div className="subtitle">
¡Seguimos guiándote!
</div>

<div className="blocks">
<div className="block1">
            <div className="image">
            <img src={data?.first_block_image}/>
            </div>

            <div className="text">

            <div className="block_title">
            {data?.first_block_subtitle} 
            </div>
            
            <div className="info">
            {data?.first_block_description}
            </div>

            </div>
</div>

<div className="block2">
            <div className="image">
            <img src={data?.second_block_image}/>
            </div>

            <div className="text">

            <div className="block_title">
            {data?.second_block_subtitle}
            </div>
            
            <div className="info">
            {data?.second_block_description}
            </div>

            </div>
</div>

<div className="block3">
            <div className="image">
            <img src={data?.third_block_image}/>
            </div>

            <div className="text">

            <div className="block_title">
            {data?.third_block_subtitle}
            </div>
            
            <div className="info">
            {data?.third_block_description}
            </div>

            </div>
</div>

</div>

<div className="subtitle">
Forma parte de nuestra comunidad
</div>

<div className="redes">

<div className="redes1">
    <a href={data?.first_network_link} target="_blank">
            <div className="img_redes">
            <img src={data?.first_network_image}/>
            </div>

            <div className="text_redes">
            <div className="redes_title">
            {data?.first_network_subtitle}
            </div>

            <div className="info_redes">
            {data?.first_network_description}
            </div>
            </div>
    </a>
</div>
<div className="redes2">
<a href={data?.second_network_link} target="_blank">

            <div className="img_redes">
            <img src={data?.second_network_image}/>
            </div>

            <div className="text_redes">
            <div className="redes_title">
            {data?.second_network_subtitle}
            </div>

            <div className="info_redes">
            {data?.second_network_description}
            </div>
            </div>
</a>

</div>

</div>

<a href={data?.button_link} target="_blank">
            <Button03>Beneficios ITCAD</Button03>
</a>

<div className="photos">
<Carousel
            className="carrusel"
 responsive={responsive}
 infinite={true}
 autoPlay={true}
 autoPlaySpeed={3000}

 keyBoardControl={true}
 transitionDuration={500}
 ssr={true}
 containerClass="carousel-container"
 >
           {Array.isArray(images) && images.map((slider, index) => (       
                    <div style={{width: '100%'}} className="img_container" key={index}>
                        {slider?.image &&  <img style={{width: '100%'}} src={slider?.image} />}
                    </div>
                ))}
          
                 {/* <img src="/images/ChooseUs/carouselimage.png"/>
                  <img src="/images/ChooseUs/itcad-success.png"/>*/}

</Carousel>
</div>

</Container>
    )
}

export default ComunidadItcad;