import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getCommunityPage } from "../../providers/communityPage.js" /*TEMPORARY FOR EXAMPLE*/

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero4 } from "../../components/Hero4"

import ComunidadItcad from "../../components/Community"

import "../../assets/css/global.css"



const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const Community = () => {
    const url2 = "https://api.itcadperu.com.pe"
    const [communityPage, setCommunityPage] = useState({}) /*TEMPORARY FOR EXAMPLE*/


    useEffect(() => {
      const fetchInfo = async () => {
        try {
          

          const _community = await getCommunityPage()/*TEMPORARY FOR EXAMPLE*/
          
         setCommunityPage(_community[0])/*TEMPORARY FOR EXAMPLE*/

        } catch (error) {
          console.log(error)
        }
      }
      fetchInfo()
    }, [])
  
    return (
        <MainLayout>
          <Container>
          <Hero4 backgroundImage={communityPage?.hero_background_image} />
            <ComunidadItcad
            data={communityPage}
            url={url2}
            images={communityPage?.community_images}
            description={communityPage?.description}/>
          </Container>
        </MainLayout>
      )
    }
    
    export default Community